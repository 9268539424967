.header {
    background-color: #f0f0f0;
    height: 52px;
    width: 100%;
    top: 0;
    position: fixed;
    /* overflow: hidden; */
    z-index: 100;
    border-bottom: 1px solid #cccccc;
    /* box-shadow: 0 5px 10px -2px #808080; */
}

.mobile .header {
    background-color: #000020;
}

.headerright {
    float: right;
    width: 180px;
    height: 52px;
}

.headerbutton {
    height: 100%;
    width: 100%;
    padding: 0 5px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
}

.headerbutton img {
    margin-top: 10px;
    float: left;
    height: 30px;
    width: 30px;
}

.headerbutton p {
    padding-top: 17px;
    padding-left: 40px;
    margin: 0 0 20px 0;
}

.headerbutton:hover {
    background-color: rgba(0, 0, 0, .2)
}

.popup {
    position: absolute;
    z-index: 1005;
    top: 52px;
    background-color: #fff;
    border: 1px solid #ddd
}

.popup ul {
    list-style: none;
    padding-left: 0;
    width: 180px
}

.popup div,
.popup li {
    padding: 5px
}

.popup div:hover,
.popup li:hover {
    background-color: #ddd
}

.menubutton {
    background-image: url(https://cp.apptosuccess.com/images/icons/menu.png);
}

.menubutton,
.backbutton {
    float: left;
    display: block;
    width: 53px;
    height: 53px;
    background-position: left top;
    background-size: 53px 53px;
}

h1 {
    color: #000080;
    padding-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 16px;
    padding-top: 5px;
}

.mobile h1 {
    color: #ffffff;
}

.mobile .headerright img {
    margin-left: 6px;
    background-color: white;
}

.mobile .headerright .teamselector {
    display: none;
}

.mobile .headerright {
    width: 52px !important;
}

.mobile .popup {
    left: 0;
    right: 0;
    width: 100%;
}

.mobile .popup ul,
.mobile .popup div {
    width: 100% !important;
}

.mobile .popup li,
.mobile .popup div {
    padding: 10px !important;
}

.hidemenu {
    background-color: black;
    opacity: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    display: none;
    left: 0px;
    top: 0px;
    z-index: 200;
}