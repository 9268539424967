.left-menu {
    float: left;
    width: 200px;
    height: 100%;
    display: block;
    position: fixed;
    background-color: #000020;
    overflow-y: auto;
    overflow-x: hidden;
    top: 0;
}

.left-menu #menulogo {
    width: 110px;
    height: 131px;
    padding: 45px;
    padding-bottom: 10px;
}

.left-menu hr {
    border-color: #ff5800;
    /* border-bottom-width: 0; */
    margin: 30px 0;
}

.left-menu a,
.left-menu a:visited {
    width: 180px;
    display: block;
    height: 30px;
    padding-left: 20px;
    padding-top: 10px;
    text-decoration: none;
    color: white;
}

.menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.menu li {
    margin: 0;
}

.left-menu a.current {
    background-color: #000080;
    /*#ff5800;*/
    color: white;
}

.left-menu a:hover {
    background-color: #ff5800;
    /*#ff5800;*/
    color: white;
}