body {
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000020;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  width: 100%;
}

table td,
table th {
  width: 110px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

table.firstColumnBig td:first-child,
table th:first-child {
  width: auto;
}

table td:first-child,
table th:first-child {
  text-align: start;
}

table th {
  font-size: small
}