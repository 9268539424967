.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.button {
  height: 40px;
  min-width: 150px;
  /*background-color: rgba(0,0,32, 0.2);*/
  background-color: white;
  color: #000080;
  border: 1px solid #000080;
  /*border: 0;*/
  border-radius: 5px;
  /*padding: 5px;
    background: #000020;
    text-align: center;
    border-radius: 14px;
    color: #ffffff;
	height: 18px;
    display: inline-block;*/
  font-size: 16px;
}

.button:hover {
  /*background: #000080;*/
  border: 1px solid #ff5800;
}

a.button,
a.button:visited {
  text-decoration: none;
  height: 18px;
  padding: 5px;
  display: inline-block;
  margin: 0;
  color: #000080;
}

tr:nth-child(even) {
  background-color: #eee;
}

table {
  border-spacing: 0;
  border-bottom: 1px solid #000020;
  width: 100%;
}

thead th {
  border-bottom: 1px solid #ff5800;
  color: #000020;
}

table td,
table th {
  padding: 15px 5px;
  width: 110px;
  text-align: center;
}

table.firstColumnBig td:first-child,
table th:first-child {
  width: auto;
}

table td:first-child,
table th:first-child {
  text-align: start;
}

table th {
  font-size: small
}